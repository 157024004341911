<template lang="pug">
div
  send-log-field( :ID="ID" v-if="logs" @send="addToList")
  logs(:value="logsShowing" v-if="logs" @click:loadMore="loadLogsMore" :model="logs" ref="log" height="calc(100vh - 400px)")
    template(v-slot:header="props")
      logsTabs(:model="props.model" :loadData="props.loadData" :getDefaultPaginationParams="props.getDefaultPaginationParams")
    template(v-slot:candidateName="{candidate, fullName}")
      span.link  {{ fullName }}
      span.link  - {{ candidate.number }}
</template>

<script>
import {mapGetters} from "vuex"
import Logs from "@/app/admin/components/Log.vue"
import CandidateLogModel from "../core/models/resultsCandidateLogModel"
import { LOG_PAGE } from '@/util/const'
import CandidateLogDTO from '@/app/admin/modules/candidates/core/DTO/candidateLogDTO'

export default {
  props: {
    ID: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    logs: null,
    logsShowing: false,
  }),

  computed: {
    ...mapGetters({
      currentUser: 'user/current'
    }),
  },

  created() {
    this.initLogs()
  },

  methods: {
    async initLogs() {
      this.logs = new CandidateLogModel(
        {candidate: this.ID, page: [LOG_PAGE.CONTANCT]}
      )
      this.logsShowing = true
    },

    loadLogs() {
      this.$refs.log.loadData({
        ...this.$refs.log.getDefaultPaginationParams()
      })
    },

    loadLogsMore() {
      this.$refs.log.loadMore({
      })
    },
    
    addToList(item) {
      this.logs.items.unshift(new CandidateLogDTO(item))
    }
  },

  components: {
    Logs,
    logsTabs: () => import('@/app/admin/modules/candidates/components/item/CandidateLogTab.vue'),
    sendLogField: () => import('@/app/admin/modules/candidates/components/item/CandidateSendLogField.vue'),
    CandidateCoursesHistory: () => import('@/app/admin/modules/candidates/components/item/CandidateCoursesHistory.vue'),
  }
}
</script>