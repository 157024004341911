import CandidateLogDTO from '@/app/admin/modules/candidates/core/DTO/candidateLogDTO'
import Api from "@/services/api/api-activityLogs"

export default class CandidatesLogModel{
  constructor(params = {}) {
    this.items = []
    this.loading = false
    this.params = params
  }

  addToCandidate(event, callback) {
    const wsMessageData = this.getMsgData(event)
    if(!wsMessageData) return
    if(!wsMessageData.page) return
    if(!this.params.page.some(page => page === wsMessageData.page)) return
    let {candidate} = wsMessageData
    if(candidate && candidate.id !== +this.params.candidate) return
    this._addToItems(wsMessageData)
    callback(wsMessageData)
  }

  async list(params, more) {
    try {
      params = {...params}
      let res = await Api.list(this._getParams(params))
      let items = res.results.map(item => new CandidateLogDTO(item))
      if(more) this.items.push(...items)
      else this.items = items
      return res
    } catch (error) {
      console.log(error)
      this.loading = false
      return Promise.reject(error)
    }
  }

  _getParams(params) {
    let _params = params
    if(this.params) _params = {...params, ...this.params}
    return _params
  }
}